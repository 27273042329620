.ReviewCampaign {
    padding: 0px 50px;

    @media screen and (max-width:768px) {
        padding: 0px;
    }

    .innerReviewCampaign {
        .trackDetail {
            .innerTrackDetail {
                // display: flex;
                // align-items: center;
                // justify-content: space-between;

                .heading {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    .headingDiv {
                        width: calc(100% - 20px);
                        h3 {
                            font-family: Poppins;
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 27.26px;
                            letter-spacing: 0.02em;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;

                            color: #242429;
                            margin: 0px;
                            margin-bottom: 7px;
                        }

                        a {
                            text-decoration: none;
                            font-family: Poppins;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 27.26px;
                            letter-spacing: 0.02em;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: #242429B2;
                        }

                        p {
                            font-family: Poppins;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 27.26px;
                            letter-spacing: 0.02em;
                            text-align: left;
                            color: #D1D1D1;
                            margin: 0px;

                        }
                        @media screen and (max-width: 768px) {
                         width: calc(100% - 40px);
                         p{
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                         }   
                        }
                    }
                }

                button {
                    background-color: transparent;
                    border: 1px solid transparent;
                }
            }

            .spotifyLink {
                padding-top: 25px;
                padding-bottom: 25px;
                border-bottom: 2px solid #D1D1D166;

                h4 {
                    font-family: Poppins;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 27.26px;
                    letter-spacing: 0.02em;
                    text-align: left;
                    color: #242429;
                    margin-bottom: 14px;
                }

                &.spotifyLinkEmail {
                    border-top: 2px solid #D1D1D166;
                }

                .compaignInput {
                    // background: linear-gradient(83.57deg, rgba(190, 16, 84, 0.2) 31.16%, rgba(190, 16, 84, 0) 95.74%);
                    padding: 14.5px 32px;
                    outline: none;
                    border: none;
                    border-radius: 12px;
                    color: #242429;
                    background: white;
                    box-shadow: 0px 4px 4px 0px #0000001C;
                    // box-shadow: -4px 0px 0px 0px #1677FF;
                    border-left: 3px solid #1677FF;
                    // margin-bottom:16px;
                    width: calc(100% - 64px);

                    &.ant-picker {
                        width: calc(100%);
                    }
                }


                .songDetail {
                    // background: linear-gradient(83.57deg, rgba(190, 16, 84, 0.2) 31.16%, rgba(190, 16, 84, 0) 95.74%);
                    padding: 14.5px 32px;
                    outline: none;
                    border: 1px solid #D1D1D1;
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: white;
                    box-shadow: 0px 4px 4px 0px #0000001C;
                    // box-shadow: -4px 0px 0px 0px #1677FF;
                    border-left: 3px solid #1677FF;
                    backdrop-filter: blur(3px);

                    .imageName {
                        display: flex;
                        align-items: center;

                        img {
                            width: 97px;
                            height: 97px;
                        }

                        .songNameArtist {
                            margin-left: 15px;

                            p {
                                font-family: Poppins;
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 27.26px;
                                letter-spacing: 0.02em;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;

                            }
                        }
                    }

                    .songDescr {
                        p {
                            font-family: Poppins;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 27.26px;
                            letter-spacing: 0.02em;
                            text-align: right;
                            color: #242429;
                        }
                    }
                }
            }

            .lyricDiv {
                .innerLyricDiv {
                    padding: 23px 0px;

                    h3 {
                        font-family: Poppins;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 27.26px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #242429;
                        margin: 0px;
                        margin-bottom: 12px;
                    }

                    p {
                        font-family: Poppins;
                        font-size: 29.24px;
                        font-weight: 700;
                        line-height: 9.33px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        color: #007AFF;
                        margin: 0px;
                        margin-top: 16px;
                    }
                }
            }

            .dateSchedule {
                padding: 16px 0px;
                border-top: 1px solid #D1D1D166;
                border-bottom: 1px solid #D1D1D166;

                .innerDateSchedule {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .dateDiv {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        width: 100%;
                        flex-direction: column;
                    }

                    .date {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        padding: 17px 0px;
                        justify-content: flex-start;

                        .dateLabel {
                            text-wrap: nowrap;
                            margin-right: 40px;
                            font-weight: 400;
                        }

                        &:first-child {
                            // padding-right: 15px;
                            // border-right: 1px solid #D1D1D166;
                        }

                        &:last-child {
                            // padding-left: 15px;
                        }

                        p {
                            font-family: Poppins;
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 27.26px;
                            letter-spacing: 0.02em;
                            text-align: left;
                            margin: 0px;
                            // color: #242429;

                            &.dateTime {
                                font-family: Poppins;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 24px;
                                text-align: left;
                                letter-spacing: 0.02em;
                                text-align: left;
                                color: #007AFF;
                                // color: #D1D1D1;
                            }
                        }
                    }

                    .disclaimerStartDate {
                        color: #242429B2;
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 27.26px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        margin: 0px;
                    }
                }
            }

            .budgetDiv {
                display: flex;
                padding: 29px 0px;
                width: 100%;
                justify-content: space-between;

                .heading {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    h3 {
                        font-family: Poppins;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 27.26px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        color: #242429;
                    }

                    h2 {
                        font-family: Poppins;
                        font-size: 29.24px;
                        font-weight: 700;
                        line-height: 9.33px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        color: #007AFF;

                        span {
                            color: #242429B2;
                            font-family: Poppins;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 27.26px;
                            letter-spacing: 0.02em;
                            text-align: left;

                        }
                    }
                }
            }

            .note {
                p {

                    color: #242429B2;
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27.26px;
                    letter-spacing: 0.02em;
                    text-align: left;
                    margin: 0px;
                }
            }
        }
    }
}