.footer-container {
    background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);

    width: 100%;
    padding: 96px 0px;

    .footer-main {
        max-width: 1317px;
        width: 100%;
        margin: auto;
        display: flex;
    }

    hr {
        max-width: 1317px;
        width: 100%;
        margin: auto;
        display: flex;
        margin-top: 56px;
    }

    .footer-section1 {
        text-align: left;
    }

    .footer-main {
        align-items: flex-end;
    }

    .SideStyleImage {
        height: 37px;
        margin-bottom: 16px;
    }

    .footer-bottom {
        max-width: 1317px;
        width: 100%;
        margin: auto;
        display: flex;
        // margin-bottom:45px;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            width: calc(100% - 40px);
            padding: 0px 20px;
        }
    }

    .footer-bottom .section3 {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: flex-end;
            p{
                text-align: right;
            }
        }
    }

    .bottom-para {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin: 16px 0px 0px 0px;
        color: white;
    }

    .bottom-para-link {
        margin-left: 32px;
    }

    .footer-para {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 400;
        line-height: 31.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        margin: 0px;
        color: white;
    }

    .footer-icon {
        margin-right: 20px;
    }

    .footer-section1 {
        width: 100%;

        @media screen and (max-width: 768px) {
            padding: 0px 20px;

        }

        .socialIconsDiv {
            img {
                width: 41px;
                aspect-ratio: 1 / 1;
                height: 41px;
            }
        }
    }

    .footer-songplace-div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            margin-top: 16px;
        }

        p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 20.8px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #FFFFFF;
            margin: 0px;
            margin-top: 16px;

            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }

}