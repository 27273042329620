.AddTrack {
  padding: 0px 50px;
  @media screen and (max-width: 768px) {
  padding: 0px;
  }
  .realeaseDate {
    display: flex;
    align-items: center;

    padding-bottom: 30px;

    &.releaseSwitchDiv {
      border-bottom: 2px solid #D1D1D166;
      margin-bottom: 31px;
    }

    p {
      color: #242429;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 27.26px;
      letter-spacing: 0.02em;
      text-align: left;
      margin: 0px;
    }
  }

  .switchDiv {
    color: blue;
    margin-right: 20px;

    .ant-switch-handle {
      margin-top: 0px;
      top: auto;
      left: 2px;
      width: 15px;
      height: 15px;
    }

    .ant-switch-checked .ant-switch-handle {
      right: 2px;
      left: auto;
    }

    .ant-switch {
      height: 20px;
      min-width: 40px;

    }

    .row {
      display: flex;
      align-items: center;
    }

    .text {
      margin-left: 10px;
      font-size: 12px;
      margin-top: 5px;

      &.bulkText {
        width: 71px;
        margin: 0px 0px 0px 10px !important;
        font-size: 11px;
      }
    }

    .spanner {
      color: blue;
      font-size: 17px;
    }

    .songplace_only {
      color: blue;
      font-size: 17px;
    }

    .text_songplace_only {
      margin-left: 10px;
      font-size: 11px;
      margin-top: 5px;
    }

    .SwitchPlayListSpannerRows_text_songplace_only {
      margin-top: 0px;
    }

    @media only screen and (max-width: 600px) {
      .songplace_only {
        font-size: 14px;
      }

      .text_songplace_only {
        font-size: 16px;
      }

      .SwitchPlayListSpannerRows_text_songplace_only {
        margin-top: 14px;

      }

    }

    @media only screen and (max-width: 412px) {
      .SwitchPlayListSpannerRows_text_songplace_only {
        margin-top: 7px;
      }
    }

    @media only screen and (max-width: 360px) {
      .songplace_only {
        font-size: 12px;
      }

      .text_songplace_only {
        font-size: 14px;
      }

      .SwitchPlayListSpannerRows_text_songplace_only {
        margin-top: 8px;
      }

    }

    .customeStyleSwitch {
      border: 1px solid white;

      margin-top: 0px;
      display: flex;
      align-items: center;
    }
  }

  .spotifyLink {
    // padding-top: 25px;
    padding-bottom: 33px;
    // border-bottom: 2px solid #D1D1D166;
    // border-top: 2px solid #D1D1D166;

    h4 {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 27.26px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #242429;
      margin-bottom: 14px;
      margin-top: 14px;
    }

    .ant-picker {
      width: 100%;
      background: linear-gradient(83.57deg, rgba(190, 16, 84, 0.2) 31.16%, rgba(190, 16, 84, 0) 95.74%);

      border: 1px solid #D1D1D1;
      border-radius: 12px;
      height: 53px;
      color: #242429;

      svg {
        color: #242429;
        font-size: 20px;
      }

    }

    .ant-picker-suffix {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px;

      .anticon {
        margin: 0px;
      }
    }

    .compaignInput {
      // background: linear-gradient(83.57deg, rgba(190, 16, 84, 0.2) 31.16%, rgba(190, 16, 84, 0) 95.74%);
      padding: 14.5px 32px;
      outline: none;
      border: none;
      border-radius: 12px;
      color: #242429;
      background: white;
      box-shadow: 0px 4px 4px 0px #0000001C;
      // box-shadow: -4px 0px 0px 0px #1677FF;
      border-left: 3px solid #1677FF;
      // margin-bottom:16px;
      width: calc(100% - 64px);

      &.ant-picker {
        width: calc(100%);
      }
    }

    .songDetail {
      // background: linear-gradient(83.57deg, rgba(190, 16, 84, 0.2) 31.16%, rgba(190, 16, 84, 0) 95.74%);
      padding: 14.5px 32px;
      outline: none;
      width: calc(100% - 64px);
      border: 1px solid #D1D1D1;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;
      background: white;
      box-shadow: 0px 4px 4px 0px #0000001C;
      // box-shadow: -4px 0px 0px 0px #1677FF;
      border-left: 3px solid #1677FF;
      backdrop-filter: blur(3px);

      .imageName {
        display: flex;
        align-items: center;

        img {
          width: 97px;
          height: 97px;
        }

        .songNameArtist {
          margin-left: 15px;

          p {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 27.26px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #242429;
          }
        }
      }

      .songDescr {
        p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 27.26px;
          letter-spacing: 0.02em;
          text-align: right;
          color: #242429;
        }
      }
    }
  }

  .lyricLanguage {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .lyricLangBtn {
      border: 1px solid #D1D1D1;
      background: #FFFFFF;

      color: #242429B2;

      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      width: 167px;
      padding: 8px 0px;
      border-radius: 8px;
      @media screen and (max-width: 768px) {
      width: calc(50% - 16px);
      // padding: 8px 24px;
      }
      &.active {
        // background:linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
        // border: 1px solid #007AFF;
        background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
        color: white;

      }
    }
  }


}

.stepsDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;

  p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 27.26px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #242429;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px 30px;
    height: 45px;

    // &:hover {
    //   // background: rgba(0, 123, 255, 0.164);
    //   // border: 1px solid rgba(0, 123, 255, 0.452);
    //   // color: #FFFFFF;
    //   // border-radius: 8px;
    // }
  }

  button {
    padding: 8px 30px;
    // background: rgb(0, 122, 255);
    border: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
    height: 48px;
    border-radius: 8px;
    background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);

  }
}