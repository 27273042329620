.CompleteProfile {
  .d-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .error {
    color: red;
    font-size: 14px;
    margin: 0px;
  }

  .innerCompleteProfile {
    padding: 0px 40px;
    @media screen and (max-width: 768px) {
    padding: 0px 24px;
    }
    h3 {
      font-family: Poppins;
      font-size: 31px;
      font-weight: 600;
      line-height: 39.37px;
      letter-spacing: 0.02em;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #007AFF;
      margin: 0px;
    }

    .form {
      .innerForm {

        display: flex;
        // align-items: center;
        justify-content: center;
        padding: 15px 0px 0px;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: center;
        }
      }

      .formDiv {
        width: 50%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        &:first-child {
          padding-right: 25px;
          border-right: 1px solid #76767666;

          @media screen and (max-width: 768px) {
            border: none;
            padding: 0px;
          }
        }

        &:last-child {
          padding-left: 25px;
          @media screen and (max-width: 768px) {
            padding: 0px;
          }
        }
      }

      .uploadPicture {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        label {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          position: relative;
        }

        img {
          background-color: #D9D9D9;
          border-radius: 100px;
          object-fit: contain;
          object-position: center;
          width: 76px;
          height: 76px;
          border-radius: 100px;
          margin-bottom: 15px;
        }

        .fileInput {
          display: none;
        }

        .uploadSvg {
          position: absolute;
          color: #242429;
          width: 76px;
          height: 76px;
          display: flex;
          align-items: center;
          background: #0000004f;
          justify-content: center;
          cursor: pointer;
          opacity: 0;
          transition: 0.2s;
          top: 0px;
          border-radius: 100px;

          &:hover {
            opacity: 1;
          }
        }

        p {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 400;
          line-height: 27.26px;
          letter-spacing: 0.02em;
          text-align: center;
          color: #007AFF;
          margin: 0px;
          // margin-top: 15px;
        }
      }

      hr {
        border-color: #D1D1D166;
        margin: 10px 0px;
        width: 100%;
      }

      .border-right {
        border-right: 2px solid #D1D1D166;
      }

      .formSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding: 0px 20px;

        label {
          width: 100%;
          display: flex;
          flex-direction: column;
          font-family: Poppins;
          font-size: 20px;
          font-weight: 400;
          line-height: 27.26px;
          letter-spacing: 0.02em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #242429;
          margin: 10px;

          input,
          textarea,
          .ReactFlagsSelect button {
            background: white;
            padding: 14.5px 20px;
            border: 0.8px solid #D1D1D1;
            border-radius: 12px;
            margin-top: 9px;
            color: #242429;
            font-size: 14px !important;
            box-shadow: 8px 8px 29px 0px #50587114;
          }
        }

        .ReactFlagsSelect .ReactFlagsSelect-module_selectOptions__3LNBJ {
          max-height: 250px;
        }

        .ReactFlagsSelect .ReactFlagsSelect-module_filterBox__3m8EU,
        .ReactFlagsSelect .ReactFlagsSelect-module_selectOptions__3LNBJ,
        .ReactFlagsSelect .ReactFlagsSelect-module_selectOptionWithlabel__2GpmM:focus,
        .ReactFlagsSelect .ReactFlagsSelect-module_selectOptionWithlabel__2GpmM:hover {
          background-color: #27272e;
        }

        .selectBoxDiv {
          width: 100%;
          display: flex;
          flex-direction: column;
          font-family: Poppins;
          font-size: 20px;
          font-weight: 400;
          line-height: 27.26px;
          letter-spacing: 0.02em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #242429;
          &.selectMusicType{
            margin-top: 9px;
          }
        }

        .showMoreOption {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #007AFF;
          margin: 0px;
          margin-top: 15px;
          cursor: pointer;
        }

        .selectBox {
          margin-top: 9px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;

          .selectBoxBtn {
            border: 1px solid #D1D1D1;
            background: #FFFFFF;
            color: #242429;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            width: 167px;
            padding: 8px 0px;
            border-radius: 8px;
            box-shadow: 8px 8px 29px 0px #50587114;
            @media screen and (max-width: 768px) {
            width: auto;
            padding: 8px 24px;
            }
            &.active {
              background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
              border: 1px solid #007AFF;
              color: white;
            }
          }
        }

        .addMoreLink {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #007AFF;
          width: 100%;
          cursor: pointer;
        }

      }

      .submitButtonDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 34px;
        border-top: 1px solid #76767666;
        margin-top: 25px;

        .submitButton {
          padding: 12px 40px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
          border-radius: 8px;
          border: none;
          color: white;
        }

      }

      .additonInputDiv {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
          width: calc(100% - 40px);
        }

        .minusButton {
          background-color: transparent;
          border: none;
          padding: 0px;
        }

        svg {
          margin-left: 10px;
          cursor: pointer;
          color: #242429;
        }
      }
    }

    .inputsSongplaceDiv {
      width: 100%;
      margin-top: 10px;
    }

    .inputsSongplace {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 27.26px;
      letter-spacing: 0.02em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #242429;
      margin-top: 9px;

      .inputSongplaceDiv {
        background-color: #FFFFFF;
        padding: 0px 20px;
        border: 0.8px solid #D1D1D1;
        // box-shadow: 0px 0px 48px 0px #7987B014 inset;

        box-shadow: 8px 8px 29px 0px #50587114;

        // box-shadow: 2px 2px 14px 0px #50587105;

        min-height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        margin-top: 9px;
      }

      input,
      textarea,
      .ReactFlagsSelect button {
        width: 100%;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        border: none;
        outline: none;

      }
    }
  }
}