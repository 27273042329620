.SubscriptionModal {
    max-width: 470px !important;
    width: 100% !important;
}

.SubscriptionModal .ant-modal-content {
    background-color: white;
    border: 1px solid #FFFFFF4D;
    border-radius: 20px;
    color: #242429;
    padding: 30px;
}

.SubscriptionModal .ant-modal-body {
    padding: 0px;
}

.innerSubscriptionModal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.innerSubscriptionModal .cancelSubsImage {
    margin-bottom: 40px;
    width: 134px;


}

.SuccessSubsImage {
    margin-bottom: 40px;
    width: 170px;
}

.innerSubscriptionModal .SubscribeBtn {
    background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
    color: #242429;
    width: 100%;
    padding: 15px 0px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    text-align: center;
    border-radius: 8px;
    border: none;
    width: 100%;
    margin-top: 20px;
    color: white;
}

.innerSubscriptionModal .loginBtn {
    background: #505050;
    margin-top: 20px;
    color: #242429;
    width: 100%;
    padding: 15px 0px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 2.3556711673736572px 2.3556711673736572px 0px #0000002B;

    box-shadow: 0px 0px 2.3556711673736572px 0px #00000015;
    border: 0.97px solid #FFFFFF4D
}

.innerSubscriptionModal h3 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    // margin-bottom: 8px;
    color: #242429;
    margin: 0px;
}

.innerSubscriptionModal p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    margin: 0px;


}

.positionWarning {
    color: red !important;
    margin-top: 8px;
}

.dontShow {
    margin-top: 14px;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.dontShow input {
    margin-right: 4px;
    background: transparent;
    background-color: transparent;
    width: 15px;
    height: 15px;
}

.campaignLoader {
    border: 7px solid #2A61EB !important;
    width: 80px !important;
    height: 80px !important;
    border-bottom-color: transparent !important;
    margin-bottom: 20px;
}