.innerSidebar {
    padding: 16px;

    .logo {
        width: 100%;
        text-align: left;
        padding: 4px;

        img {
            width: 164px;
        }

    }

    .userName {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .userAvName {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 32px;
                height: 32px;
                border-radius: 40px;
            }

            p {
                font-family: 'Poppins';
                font-size: 16px;
                font-weight: 500;
                line-height: 20.83px;
                letter-spacing: -0.03em;
                text-align: left;
                color: white;
                margin-left: 12px;
                width: 130px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .menuDiv {
        width: 100%;

        p {
            font-family: Poppins;
            font-size: 12px;
            // font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #ffffffa1;
        }

        .navLink {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            padding: 0px 10px;
            border-radius: 10px;

            svg {
                margin-right: 14px;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }

            &.active {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }

    .createCampaign {
        padding: 9.5px 0px;
        width: 100%;
        background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
        border-radius: 6px;
        border: none;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
        color: white;
        margin-top: 12px;
        cursor: pointer;
        background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);

    }

    .seprator {
        margin-top: 24px;
        border-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 24px;
    }
}


.CreateCampaignSideBar {
    width: 373px;

    @media screen and (max-width: 768px) {
        display: none;
    }

    .innerSideBar {
        position: relative;
        padding-top: 44px;
        padding-bottom: 44px;
        display: flex;
        align-items: center;
        justify-content: center;

        .monetizationSidebarBg {
            position: absolute;
            width: calc(100% - 36px);
            top: 0px;
            border-radius: 34px;
            border: 1px solid #D1D1D1;
            height: 100%;
            box-shadow: 8px 8px 29px 0px #50587114;

        }

        .stepCampaign {
            width: 100%;

            .step {
                display: flex;
                align-items: flex-start;
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
                z-index: 1;
                position: relative;
                // width: 100%;
                padding: 30px;

                .stepContentIamge {
                    background: white;
                    // padding: 10px;
                    margin-right: 16px;
                    border-radius: 12px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 47px;
                    width: 47px;
                    aspect-ratio: 1;

                    img {
                        width: 27px;
                    }
                }

                .stepContentText {
                    color: white;
                    cursor: pointer;


                    h3 {
                        color: white;
                        margin: 0px;
                        text-align: left;
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 27.26px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;

                    }

                    p {

                        margin: 0px;
                        text-align: left;
                    }
                }

                &.active {
                    // background:linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                    background: white;

                    .stepContentIamge {
                        background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                    }


                    .stepContentText {
                        color: white;
                        cursor: pointer;

                        h3 {
                            color: #242429;
                            font-family: Poppins;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 27.26px;
                            letter-spacing: 0.02em;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            margin: 0px;

                        }

                        p {
                            color: #242429;
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 27.26px;
                            letter-spacing: 0.02em;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            margin: 0px;

                            b {
                                font-weight: 700;
                            }
                        }
                    }
                }
            }

        }
    }
}

.dropdownSideBar {
    .ant-dropdown-menu {
        // background: linear-gradient(180.01deg, #242429 0.01%, #242429 82.2%);
        box-shadow: 0px 4px 4px 0px #0000001C;
        // box-shadow: -4px 0px 0px 0px #1677FF;
        border-left: 3px solid #1677FF;
        backdrop-filter: blur(3px);
        // margin-bottom:16px;

        li {
            color: #242429B2 !important;
        }
    }
}

.notiDropDown {
    width: 291px;

    ul {

        max-height: 275px;
        overflow: auto;

        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    // &:not(:last-child){
    .notificationBox {
        border-bottom: 1px solid #FFFFFF80;
        padding-bottom: 14px;
        // }
    }
}

.createCampaignTopBar {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: auto;
    display: none;
    background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);

    @media screen and (max-width: 768px) {
        display: flex;
    }

    .innerCreateCampaignTopBar {
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        gap: 16px;
        padding: 12px;
        /* Adjust gap between tabs if needed */

    // .createCampaignTabs {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;

        .campaignTab {
            display: flex;
            align-items: flex-start;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
            z-index: 1;
            position: relative;
            // width: 100%;
            width: 200px;
            height: 200px;
            padding: 12px;
            flex-direction: column;
            min-width: 200px;
            .stepContentIamge {
                background: #fff;
                // padding: 10px;
                margin-right: 16px;
                border-radius: 12px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 47px;
                width: 47px;
                aspect-ratio: 1;
                margin-bottom: 12px;

                img {
                    width: 27px;
                }
            }

            .stepContentText {
                color: white;
                cursor: pointer;
                width: 100%;


                h3 {
                    color: white;
                    margin: 0px;
                    text-align: left;
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 27.26px;
                    letter-spacing: 0.02em;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    margin: 0px;
                    width: 100%;
                    white-space: normal;
                }

                p {

                    margin: 0px;
                    text-align: left;
                    width: 100%;
                    white-space: normal;
                }
            }

            &.active {
                // background:linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                background: white;
                border-radius: 10px;

                .stepContentIamge {
                    background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                }


                .stepContentText {
                    color: white;
                    cursor: pointer;
                    width: 100%;

                    h3 {
                        color: #242429;
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 27.26px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        margin: 0px;
                        width: 100%;
                        white-space: normal;
                    }

                    p {
                        color: #242429;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 27.26px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        margin: 0px;
                        width: 100%;
                        white-space: normal;
                        b {
                            font-weight: 700;
                        }
                    }
                }
            }

        }
    }
// }
}
