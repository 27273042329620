.MonetizingHeader {
    width: 100%;

    .innerMonetizingHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 24px;

        img {
            width: 190px;
            position: absolute;
            left: 34px;

        }

        h2 {
            font-family: Poppins;
            font-size: 31px;
            font-weight: 500;
            line-height: 46.5px;
            letter-spacing: -0.03em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #007AFF;
        }

        .logoImage {
            cursor: pointer;
            z-index: 1;
        }
    }

    .notificationIcon {
        position: absolute;
        right: 1%;
    }

    .navlink a,
    .navlink span {
        margin-right: 20px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
        color: #242429;
        text-decoration: underline;

        svg {
            cursor: pointer;
            color: #242429;
        }
    }

    .notificationIcon {
        color: #242429;

        svg {
            cursor: pointer;
            color: #242429;
        }
    }

    &.campaingSideBar {
        @media screen and (max-width: 768px) {

            // padding: 12px;
            .innerMonetizingHeader {
                padding: 12px;
                flex-wrap: wrap;

                .logoImage {
                    position: static;
                    order: 1;
                }

                .navlink {
                    order: 3;
                }

            }

            .notificationIcon {
                position: static;
                width: calc(100% - 190px);
                order: 2;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

        }
    }
}

.notificationBox {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}