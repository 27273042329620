.PrivacyPolicyTopHeading {
    font-weight: 700 !important;
    font-size: 25px !important;
}

.PrivacyPolicyParam {
    font-size: 12.53px;
}

@media only screen and (max-width: 600px) {
    .heading {
        font-size: 26px;
        margin: 0px;
    }

    .PrivacyPolicyTopHeading {
        font-weight: 700 !important;
        // font-size: !important;
    }

    .PrivacyPolicyHeading {
        font-weight: 700 !important;
        font-size: 22px !important;
    }

    .PrivacyPolicyParam {
        font-size: 14px !important;
        font-weight: 500 !important;
    }
}

.justifywidth {
    text-align: left;
    padding: 6% 2%;
}