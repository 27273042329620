.AccountOverview {
    hr {
        border: 1px solid rgba(209, 209, 209, 0.4);
        margin: 28px 0px;
    }

    .innerAccountOverview {
        @media screen and (max-width: 768px) {
            padding: 24px;
        }

        .userNameDiv {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and (max-width: 768px) {
                position: relative;
            }

            .userName {
                display: flex;
                align-items: center;

                @media screen and (max-width: 768px) {
                    flex-direction: column;
                }

                .dp {
                    width: 176px;
                    height: 176px;

                    @media screen and (max-width: 768px) {
                        width: 80px;
                        height: 80px;
                        min-width: 80px;
                    }

                    img {
                        border-radius: 100%;
                        width: 100%;
                        height: 100%;
                    }
                }

                .nameEmail {
                    margin-left: 27px;

                    @media screen and (max-width: 768px) {
                        margin-left: 10px;
                    }

                    h4 {
                        font-family: Poppins;
                        font-size: 38px;
                        font-weight: 600;
                        line-height: 48.26px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        margin: 0px;
                        color: #242429;
                    }

                    p {
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 22.86px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        margin: 0px;
                        color: #242429B2;
                        overflow-wrap: break-word;
                        word-wrap: break-word;
                        /* Fallback for older browsers */
                        white-space: normal;
                    }
                }
            }
        }

        .editIcon {
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 768px) {
                position: absolute;
                top: 0px;
                right: 0px;
            }
        }

        .textBox {
            padding: 0px 30px;

            @media screen and (max-width: 768px) {
                padding: 0px;
            }

            h4 {
                font-family: Poppins;
                font-size: 22px;
                font-weight: 600;
                line-height: 27.94px;
                letter-spacing: 0.02em;
                text-align: left;
                color: #242429;
                margin: 0px;
            }

            p {
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 26.28px;
                letter-spacing: 0.02em;
                text-align: left;
                color: #242429B2;
                margin: 0px;
            }

            .charMy {
                margin-top: 24px;
            }
        }
    }
}