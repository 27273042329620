.Home {
    @media screen and (max-width: 768px) {
        padding: 24px;
        }
    .innerHome {
        .publicUrlButton {
            padding: 9.5px 10px;
            // width: 100%;
            background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
            border-radius: 6px;
            border: 1px solid #007aff;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: center;
            color: #242429;
            margin-top: 12px;
            cursor: pointer;
        }

        .noArtistFound {
            // display: flex;
            // align-items: center;
            // flex-direction: column;
            // align-items: center;
            // justify-content: center;

            h4 {
                font-family: Poppins;
                font-size: 22px;
                letter-spacing: 0.04em;
                text-align: left;
                color: rgba(255, 255, 255, 0.631372549);
            }

            .buttonDiv {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                button {
                    background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                    width: 207px;
                    height: 45px;
                    padding: 12px 40px 12px 40px;
                    border-radius: 8px;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: white;
                    border: none;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }

                }
            }

            .artistInfo {
                border-radius: 15px;
                display: flex;
                align-items: center;
                box-shadow: 0px 4px 4px 0px #0000001C;
                // box-shadow: -4px 0px 0px 0px #1677FF;
                border-left: 3px solid #1677FF;
                // backdrop-filter: blur(3px);
                margin-top: 23px;
                padding: 21px 25px 17px;
                background-color: #FFFFFF;
                box-shadow: 0px 4px 4px 0px #0000001C;

                .innerArtistInfo {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    flex-direction: column;
                    .artistDetail{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        @media screen and (max-width: 768px) {
                        flex-direction: column-reverse;
                        }
                    }

                    .nameFollowers {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 100%;
                        h4 {
                            font-family: Poppins;
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 30px;
                            text-align: center;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: #1677FF;
                            margin: 0px;
                        }

                        .followers {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                            text-align: center;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            margin: 0px;
                            color: #A3A3A3;
                            margin-top: 7px;

                        }
                    }

                    .editProfile {
                        cursor: pointer;
                        font-family: Poppins;
                        font-size: 10px;
                        font-weight: 500;
                        line-height: 15px;
                        text-align: center;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #A3A3A3;
                        border: 1px solid #D1D1D1;
                        border-radius: 5px;
                        padding: 6px 11px;
                        @media screen and (max-width: 768px) {
                        width: 100%;
                        font-size: 14px;
                        margin-bottom: 21px;
                        }
                        svg {
                            width: 10px;
                            height: 10px;
                            color: #A3A3A3;

                            path {
                                fill: #A3A3A3;
                            }
                        }
                    }
                }

                .tags {
                    display: flex;
                    align-items: center;
                    margin-top: 17px;
flex-wrap: wrap;
row-gap: 10px;
column-gap: 10px;
                    .innetTags {
                        background: linear-gradient(90deg, rgba(42, 97, 235, 0.3) 0%, rgba(144, 53, 234, 0.3) 100%);
                        padding: 1px 14px;
                        font-family: Poppins;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 19.5px;
                        text-align: center;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #FFFFFF;
                        border-radius: 54px;
                        // margin-right: 4px;
white-space: nowrap;
                    }
                }
            }

            .artistData {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 23px;
                border-radius: 15px;
                @media screen and (max-width: 768px) {
                flex-wrap: wrap;
                }
                .artistDataDiv {
                    box-shadow: 0px 4px 4px 0px rgba(42, 97, 235, 0.1098039216);
                    background: #FFFFFF;
                    width: calc(50% - 13.5px);
                    border-radius: 15px;

                    border-left: 3px solid #9035EA;
                    // backdrop-filter: blur(3px);
                    margin-top: 23px;
                    background-color: #FFFFFF;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1098039216);
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                    .innerArtistDataDiv {
                        padding: 22px 25px;

                        h4 {
                            font-family: Poppins;
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 30px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: #1677FF;
                            margin: 0px;
                            margin-bottom: 15px;
                        }

                        .listArtists {
                            .row {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 18px 0px;
                                border-bottom: 1px solid #0000001A;
                                @media screen and (max-width: 768px) {
                                flex-direction: column;
                                width: 100%;
                                }
                                .rowContent {
                                    width: calc(100% - 174px);
                                    @media screen and (max-width: 768px) {
                                    width: 100%;
                                    }
                                    h5 {
                                        font-family: Poppins;
                                        font-size: 16px;
                                        font-weight: 500;
                                        line-height: 24px;
                                        text-align: left;
                                        text-underline-position: from-font;
                                        text-decoration-skip-ink: none;
                                        color: #242429;
                                        margin: 0px;
                                        white-space: nowrap;
                                        /* Prevents the text from wrapping */
                                        overflow: hidden;
                                        /* Hides the overflowing text */
                                        text-overflow: ellipsis;
                                        /* Adds ellipsis for overflow */
                                        display: block;
                                    }

                                    p {
                                        font-family: Poppins;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 21px;
                                        text-align: left;
                                        text-underline-position: from-font;
                                        text-decoration-skip-ink: none;
                                        color: #A3A3A3;
                                        margin: 0px;
                                    }
                                }

                                .rowButton {
                                @media screen and (max-width: 768px) {
                                padding-top: 18px;
                                }
                                    button {
                                        width: 154px;
                                        height: 32px;
                                        padding: 5.5px 13px 5.5px 13px;
                                        border-radius: 31px;
                                        opacity: 0px;
                                        background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                                        border: none;
                                        color: white;

                                        font-family: Poppins;
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 21px;
                                        text-align: center;
                                        text-underline-position: from-font;
                                        text-decoration-skip-ink: none;

                                    }


                                }
                            }
                        }
                    }
                }
            }
        }

        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        // flex-wrap: wrap;
        .headingSearch {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column-reverse;

            h2 {
                font-family: Poppins;
                font-size: 31px;
                font-weight: 500;
                line-height: 46.5px;
                letter-spacing: -0.03em;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #007AFF;
                margin: 0px;
            }

            label {
                background-color: #FFF;
                max-width: 473px;
                width: 100%;
                padding: 9px 20px;
                border: none;
                border-radius: 8px;
                display: flex;
                align-items: center;
                box-shadow: 0px 4px 4px 0px #0000001C;
                // box-shadow: -4px 0px 0px 0px #1677FF;
                border-left: 3px solid #1677FF;
                // backdrop-filter: blur(3px);

                input {
                    width: calc(100% - 56px);
                    background-color: transparent;
                    color: #ffffff52;
                    border: none;
                    margin-left: 12px;

                }
            }
        }

        .playlists {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;

            .comingSoon {
                /* height: 100%; */
                position: absolute;
                color: #242429;
                top: 0px;
                width: calc(100% + 20px);
                height: 100%;
                margin: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                /* filter: blur(39px); */
                z-index: 31;
                // backdrop-filter: blur(4px);
                left: -10px;
                font-size: 28px;
            }


        }
    }
}

.URLModal {
    .urlInput {
        background: white;
        box-shadow: 8px 8px 29px 0px #50587114;

        padding: 14.5px 32px;
        outline: none;
        width: calc(100% - 64px);
        border: 1px solid #D1D1D1;
        border-radius: 12px;
        color: #242429;
    }

    .btnDiv {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            padding: 9.5px 20px;
            background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
            border-radius: 6px;
            border: 1px solid #007aff;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: center;
            color: #242429;
            margin-top: 12px;
            cursor: pointer;
            color: white;
        }
    }

}