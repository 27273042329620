.PlaylistCard {
    width: calc(50% - 12.5px);
    margin-bottom: 12.5px;
    margin-top: 12.5px;
    background: linear-gradient(83.57deg, rgba(190, 16, 84, 0.1) 31.16%, rgba(190, 16, 84, 0) 95.74%);
    border: 0.8px solid #d1d1d194;
    box-shadow: 0px 0px 48px 0px #7987B014 inset;
    border-radius: 12px ;
    .innerPlaylistCard {
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            font-family: Poppins;
            font-size: 21.53px;
            font-weight: 600;
            line-height: 27.26px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #242429;
            margin: 0px;
        }

        p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 27.26px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #242429;
            margin: 0px;
        }

        .activeMark {
            display: flex;
            align-items: center;
            span {
                width: 10px;
                height: 10px;
                background-color: #8FDB0B;
                border-radius: 100px;
                display: block;
                margin-right: 10px;
            }

            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 27.26px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #242429;
        }

        .image {
            width: 94px;
            aspect-ratio: 1;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .lastUpdated{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #D9D9D91A;
        padding: 3px 14px;
        p{
            font-family: Poppins;
            font-size: 14.53px;
            font-weight: 400;
            line-height: 27.26px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #FFFFFF80;
        }
    }
}