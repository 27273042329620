.LandingPage {
    position: relative;
    overflow: hidden;


    .heroSection {
        padding: 144px 24px 119px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-position: top left;
        background-repeat: no-repeat;
        background-image: url('./../../Assets/Images/heroBanner.png');
        background-size: cover;

        @media screen and (max-width:768px) {
            padding-bottom: 80px;
        }

        .innerHeroSection {
            display: flex;
            align-items: center;
            background-size: 100% 100%;
            max-width: 1313px;
            width: 100%;

            @media screen and (max-width:768px) {
                flex-direction: column;
            }

            .heroSectionText {
                width: calc(50% - 10px);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                @media screen and (max-width:768px) {
                    width: 100%;
                }

                .getYourSong {
                    background: linear-gradient(90deg, #2A61EB 0%, #7A3EEA 47.5%, #9035EA 100%);
                    margin-bottom: 16px;
                    font-family: Poppins;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: white;
                    padding: 11px 14px;
                    border-radius: 6px;
                }

                h1 {
                    font-family: Poppins;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 46.8px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;

                    color: #242429;
                    margin: 0px;
                    margin-bottom: 16px;

                    span {
                        background: linear-gradient(90deg, #9332F2 0%, #2B61EB 72.97%);
                        -webkit-background-clip: text;
                        /* Clipping the background to the text */
                        -webkit-text-fill-color: transparent;
                    }
                }

                h3 {
                    font-family: Poppins;
                    font-size: 21.81px;
                    font-weight: 600;
                    line-height: 32.72px;
                    letter-spacing: 0.02em;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #000;
                    margin: 0.0px;
                    margin-top: 24.8px;
                }

                p {
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 25.6px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;

                    color: #000;
                    margin: 0px;
                    margin-bottom: 16px;

                }

                .startCampaignSignup {
                    display: flex;

                    @media screen and (max-width:768px) {
                        // flex-direction: column;
                        justify-content: space-between;
                        width: 100%;
                    }

                    .campaign {
                        background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 27px;
                        text-align: center;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        width: 240px;
                        height: 51px;
                        color: white;
                        // padding: 12px 40px;
                        border: none;
                        border-radius: 8px;

                        @media screen and (max-width: 768px) {
                            font-size: 16px;
                            width: 60%;
                        }
                    }

                    .signUp {
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 27px;
                        text-align: center;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        width: 149px;
                        height: 51px;
                        color: white;
                        // padding: 12px 40px;
                        border: none;
                        border-radius: 8px;

                        span {
                            background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }

                        /* Clipping the background to the text */
                        border: 2px solid #2A61EB;
                        background: transparent;
                        margin-left: 17px;

                        @media screen and (max-width:768px) {
                            font-size: 16px;
                            margin: 0px;
                            width: calc(40% - 12px);
                        }


                    }
                }
            }

            .heroImage {

                top: 57.6px;
                width: 50%;
                // background-image: url('./../../Assets/Images/tabletHero.png');
                // background-repeat: no-repeat;
                // background-size: contain;
                // background-position: center;
                // height: 654.4px;
                // position: absolute;
                right: 51.2px;
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media screen and (max-width:768px) {
                    width: 100%;
                    margin-top: 16px;
                }

                .heroImageLottie {
                    max-width: 528px;
                    width: 100%;
                    aspect-ratio: 1.225;
                    position: relative;

                    div {
                        position: absolute;
                    }
                }

            }

            .bgGrad {

                background-image: url('./../../Assets/Images/bgGradient.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                /* Forces it to stretch perfectly */
                background-position: top;
                /* Ensures proper alignment */
                width: 715.2px;
                aspect-ratio: 1;
                position: absolute;
                right: -68.0px;
                height: 100%;
                z-index: 1;
                top: 0;
                /* Removed offset */

            }
        }
    }

    @media screen and (min-width:819.2px) {

        .heroSection {

            .bgGrad {

                background-image: url('./../../Assets/Images/bgGradient.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                /* Forces it to stretch perfectly */
                background-position: top;
                /* Ensures proper alignment */
                width: 475.2px;
                aspect-ratio: 1;
                position: absolute;
                right: -68.0px;
                height: 100%;
                z-index: 1;
                top: 0;
                /* Removed offset */

            }

            .heroImage {
                top: 57.6px;
                width: 50%;
                // height: 596.0px;
                right: -28.8px;
                z-index: 2;
            }

        }
    }

    @media screen and (min-width:1092.8px) {

        .heroSection {



            .heroImage {
                top: 57.6px;
                width: 50%;
                // height: 596.0px;
                right: -28.8px;
                z-index: 2;
            }

        }
    }






    .aboutUsSection {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 110px 24px;
        position: relative;
        background-color: #F4F5F6;

        @media screen and (max-width:768px) {
            padding: 80px 19.2px;
        }

        .bgGrad {
            background-image: url('./../../Assets/Images/bgGradient2.png');
            background-repeat: no-repeat;
            background-size: cover;
            width: 600.0px;
            aspect-ratio: 1;
            position: absolute;
            left: -200.0px;
            bottom: -184.0px;
            z-index: 1;
            transform: rotateZ(175deg);
            opacity: 0.3;

        }

        .innerAboutUsSection {
            // max-width: 960.0px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-shadow: 1.6px 1.6px 11.2px 0.0px #50587105;
            border-radius: 19.2px;
            // background: white;
            // padding: 48.0px 19.2px;
            z-index: 2;
            max-width: 1312px;

            @media screen and (max-width:768px) {
                flex-direction: column-reverse;

            }

            .aboutUsImg {
                // max-width: 640px;
                width: 50%;

                @media screen and (max-width:768px) {
                    width: 100%;
                    margin-top: 16px;
                }

                // padding: 0.0px 32.0px;
                .lottieDiv {
                    max-width: 640px;
                    width: 100%;
                    aspect-ratio: 1.176;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    div {
                        position: absolute;
                    }

                    img {
                        border-radius: 20.0px;
                        // background-color: #D9D9D9;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .aboutUsContent {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px 32px;
                max-width: calc(100% - 810px);
                width: 100%;

                @media screen and (max-width:768px) {
                    width: 100%;
                    margin-bottom: 25.6px;
                    max-width: 100%;
                    align-items: center;
                }

                h3 {
                    font-family: Poppins;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 36px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;

                    background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin: 0px;
                    margin-bottom: 16px;
                }

                p {
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 25.6px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #4F4F4F;
                    margin: 0px;
                    margin-bottom: 16px;

                    @media screen and (max-width:768px) {
                        margin-bottom: 25.6px;
                    }
                }

                .campaignStartBtn {
                    background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 27px;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    width: 240px;
                    height: 51px;
                    color: white;
                    border: none;
                    border-radius: 8px;

                    // box-shadow: 0.0px 0.0px 1.89px 0.0px #00000015;
                    @media screen and (max-width:768px) {
                        z-index: 2;
                    }


                }
            }
        }
    }

    .featuresSection {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 110px 19.2px;
        position: relative;
        background-image: url('./../../Assets/Images/Frame\ 1577705577.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: white;

        @media screen and (max-width:768px) {
            padding: 80px 19.2px;
        }

        .innerFeaturesSection {
            max-width: 1313px;
            width: 100%;
            border-radius: 19.2px;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h3 {
                font-family: Poppins;
                font-size: 36px;
                font-weight: 600;
                line-height: 36px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 0px;
                margin-bottom: 16px;
            }

            p.headingText {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 25.6px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;

                color: #4F4F4F;
                margin: 0px;
            }

            .featuresDiv {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;
                justify-items: center;
                flex-wrap: wrap;
                row-gap: 42px;
                column-gap: 32px;
                margin-top: 64px;
                max-width: 1000px;
                width: 100%;

                @media screen and (max-width: 1023px) {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-wrap: wrap;
                }

                .featDiv {
                    width: 304px;
                    height: 238px;

                    text-align: left;
                    background-color: #FFFFFF;
                    // box-shadow: 1.6px 1.6px 11.2px 0.0px #50587105;
                    border-radius: 19.2px;
                    // border: 0.64px solid #D1D1D1;
                    min-height: calc(186.4px - 25.6px - 25.6px);
                    display: flex;
                    align-items: center;

                    .innerFeatDiv {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        padding: 25.6px;
                    }

                    img {
                        width: 64px;
                        aspect-ratio: 1;
                        margin-bottom: 24px;
                    }

                    h5 {
                        font-family: Poppins;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 16px;
                        text-align: center;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;

                        color: #000;
                        margin: 12.8px 0.0px 0.0px;
                    }

                    p {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19.6px;
                        text-align: center;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;

                        margin: 0.0px;
                        margin-top: 9.6px;
                        color: #242429B2;
                    }

                }
            }
        }
    }

    .budgetSection {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 110px 19.2px;
        position: relative;

        @media screen and (max-width:768px) {
            padding: 80px 19.2px;
        }

        .bgGrad.last {
            background-image: url('./../../Assets/Images/bgGradient2.png');
            background-repeat: no-repeat;
            background-size: cover;
            width: 512.0px;
            aspect-ratio: 1;
            position: absolute;
            right: -208.0px;
            bottom: -240.0px;
            z-index: 1;
            opacity: 0.8;
            // transform: rotateZ(175deg);
        }



        .innerBudgetSection {
            max-width: 1313px;
            width: 100%;
            border-radius: 19.2px;
            padding: 0.0px;
            z-index: 2;

            h3 {
                font-family: Poppins;
                font-size: 36px;
                padding: 0;
                font-weight: 600;
                line-height: 46.8px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                margin: 0px;
                margin-bottom: 16px;

                span {
                    background: linear-gradient(79.05deg, #9035EA 12.43%, #2B61EB 80.15%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin: 0px;
                }

            }

            p {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 25.6px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #4F4F4F;
                margin: 0px;
                margin-bottom: 16px;
            }
        }
    }

    .newsLetterSection {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 110px 24px 100px;
        position: relative;
        background-color: white;

        @media screen and (max-width:768px) {
            padding: 80px 19.2px;
        }

        .innerNewsLetterSection {
            max-width: 672.8px;
            width: 100%;
            border-radius: 19.2px;
            z-index: 2;

            h3 {
                font-family: Poppins;
                font-size: 36px;
                font-weight: 600;
                line-height: 46.8px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 0px;
                margin-bottom: 16px;
            }

            p {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.6px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #4F4F4F;
                margin: 0px;
            }

            .newsLetterInput {
                // box-shadow: 1.6px 1.6px 11.2px 0.0px #50587105;
                border: 9.6px;
                background-color: white;
                padding: 12px;
                display: flex;
                align-items: center;
                border-radius: 9.6px;
                justify-content: space-between;
                // box-shadow: 6.4px 6.4px 23.2px 0.0px #50587114;
                margin-top: 32px;
                border: 1px solid #D1D1D1;

                @media screen and (max-width: 768px) {
                    flex-direction: column;
                }

                input {
                    width: calc(100% - 6.4px - 25.6px - 116.8px);
                    border: none;
                    outline: none;
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22.4px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;

                    color: #4F4F4F;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                        font-size: 18px;
                        font-weight: 500;
                        padding: 10px 20px;
                        text-align: center;
                    width: calc(100% - 24px);
                }
                }

                .submitNewsLetter {
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 27px;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;

                    color: #FFFFFF;
                    margin: 0px;
                    background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                    padding: 8px 24px;
                    color: #FFFFFF;
                    border-radius: 6.4px;
                    border: none;
                    box-shadow: 0.0px 1.89px 1.89px 0.0px #0000002B;

                    // height: 45.95px;
                    // width: 116.8px;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                }

            }
        }
    }
}