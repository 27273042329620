.ViewCampaign {
    @media screen and (max-width: 768px) {
        padding: 24px;
        }
    .innerViewCampaign {
        .headingSearch {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column-reverse;

            h2 {
                font-family: Poppins;
                font-size: 31px;
                font-weight: 600;
                line-height: 46.5px;
                letter-spacing: -0.03em;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #007AFF;
                margin: 0px;
            }

            label {
                background-color: #FFF;
                max-width: 473px;
                width: 100%;
                padding: 9px 20px;
                border: none;
                border-radius: 8px;
                display: flex;
                align-items: center;
                box-shadow: 0px 4px 4px 0px #0000001C;
                // box-shadow: -4px 0px 0px 0px #1677FF;
                border-left: 3px solid #1677FF;
                backdrop-filter: blur(3px);

                input {
                    width: calc(100% - 56px);
                    background-color: transparent;
                    color: #ffffff52;
                    border: none;
                    margin-left: 12px;

                }
            }
        }
    }
}