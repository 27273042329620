.LandingPageBody {
    // width: 100vw;
    // height: 100vh;
    // overflow: auto;
    scroll-behavior: smooth;
    width: 100%;

}

.navbarDiv {
    position: relative;
    display: flex;
    align-items: center;
}

.LandingNavbar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10;

    padding-top: 16px;

    @media screen and (max-width: 768px) {
        padding: 0px;
        height: auto;
        width: calc(100%);
    }

    .innerNavbar {
        width: 100%;
        max-width: 1313px;
        background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
        border-radius: 15px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        // height: 87px;

        @media screen and (max-width: 768px) {
            border-radius: 0px;
        }

        .innerNavbarDiv {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 18px;

        }

        .logoDiv {
            max-width: 198px;
            width: 100%;
            @media screen and (max-width: 768px) {
            order: 1;
            padding: 0px 12px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .navLinks {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .link {
                font-family: Poppins;
                font-size: 14.4px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #FFFFFF;
                padding: 0px 10px;

                a {
                    color: #FFFFFF;
                    text-decoration: none;
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    position: relative;
                    display: flex;
                    // align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &.active {
                        &::before {
                            content: "";
                            position: absolute;
                            width: 22px;
                            height: 1px;
                            bottom: -10px;
                            left: 50%;
                            transform: translate(-50%, 0);
                            background-color: #FFFFFF;
                            transition: all 0.3s ease-in-out;
                        }
                    }
                }

                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }

        .loginButtn {
            display: flex;
            align-items: center;

            .link {

                color: #FFFFFF;
                text-decoration: none;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                position: relative;
                display: flex;
                // align-items: center;
                justify-content: center;
                padding: 13px 18px;
                cursor: pointer;

            }

            button {
                font-family: Poppins;
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                padding: 12px 40px;
                color: #000;
                background: white;
                border-radius: 8px;
                border: none;
                cursor: pointer;
            }
        }

        .burgerIcon {
            background: transparent;
            color: white !important;
            border: 1px solid white !important;
            font-size: 20px;
            aspect-ratio: 1;
            width: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            display: none;
            fill: white !important;
            stroke: white !important;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                display: flex;
                order: 0;

            }
            svg{
                fill: white !important;
                stroke: white !important;
            color: white !important;
        }
        }

        .user {
            cursor: pointer;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            color: white;
            background: #ffffff36;
            border-radius: 10px;
            border: 1px solid white;
            width: 112px;
            @media screen and (max-width: 768px) {
            width: auto;
            order: 3;
            }
            img {
                width: 32px;
                height: 32px;
                margin-right: 10px;
                background-color: #e7e7e7;
                border-radius: 10px;
                @media screen and (max-width: 768px) {
                margin: 0px;
                }
            }

            .userName {
                border-left: 1px solid white;
                padding-left: 10px;
                height: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12.8px;
                @media screen and (max-width: 768px) {
                display: none;
                }
            }
        }
    }
}

.menuDrawerRoot {
    .ant-drawer-content-wrapper {
        @media screen and (max-width: 768px) {
            width: 80vw !important;
        }
    }

    .menuDrawer {
        width: 100% !important;
        background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%) !important;

        ant-drawer-content-wrapper .ant-drawer-header-title {
            flex-direction: row-reverse;
        }

        .logoDiv {
            width: 70%;

            img {
                width: 100%;
            }
        }

        .navLinksDiv {
            padding: 24px 12px;
            background-color: white;
            margin-top: 16px;
            border-radius: 16px;

            .navLinks {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;

                .link {
                    font-family: Poppins;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #FFFFFF;
                    padding: 6px 10px;
                    color: white;
                    font-size: 18px;

                    a {
                        background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-size: 18px;
                        font-weight: 500;
                    }

                    &:not(:last-child) {
                        margin-right: 15px;
                    }
                }
            }

            .loginButtn {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;

                .link {
                    font-family: Poppins;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #FFFFFF;
                    padding: 6px 10px;
                    color: white;

                    background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 18px;
                    font-weight: 500;
                }

                button {
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: normal;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    padding: 12px 32px;
                    color: #fff;
                    background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
                    border-radius: 8px;
                    border: none;
                    margin-top: 6px;
                    width: 100%;
                }
            }
        }
    }
}

.sideBarDrawerRoot {
    background-color: linear-gradient(180.01deg, #242429 0.01%, #242429 82.2%);

    .ant-drawer-content-wrapper {
        width: 250px !important;
    }

    .sideBarDrawer {
        background-color: linear-gradient(180.01deg, #242429 0.01%, #242429 82.2%) !important;
        background: linear-gradient(180.01deg, #242429 0.01%, #242429 82.2%) !important;
    }

    .ant-drawer-body {
        padding: 0px;
    }
}

.drawerOpenBtn {
    display: none;
    background: transparent;
    border: none;
    font-size: 24px;
    color: #000000a1;
    position: absolute;
    z-index: 11;

    @media screen and (max-width: 1024px) {
        display: block;
    }
}

.profileDropDown {
    li {
        &:last-child {
            border-top: 1px solid #2b60eb;
            border-radius: 0px;
        }

        // border-left: 2px solid #2b60eb;
    }

    .logoutNavbar {
        font-size: 11.2px;
        width: 100%;
    }
}

.LandingNavbarModal {
    max-width: 737px !important;
    width: 100% !important;

    @media screen and (max-width: 768px) {
        padding: 24px;
    }

    .ant-modal-content {
        padding: 48px;

        @media screen and (max-width: 768px) {
            padding: 24px;
        }
    }

    h3 {
        font-family: Poppins;
        font-size: 36px;
        font-weight: 600;
        line-height: 36px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
        -webkit-background-clip: text;
        /* Clipping the background to the text */
        -webkit-text-fill-color: transparent;
        margin: 0px;
    }

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 25.6px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin: 0px;
    }

    .formDiv {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 24px;

        input {
            background-color: #F6F6F6;
            border: none;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #545454;
            padding: 18px 24px;
            border-radius: 12px;
            width: calc(100% - 48px);
            outline: none;
            margin-bottom: 16px;
        }

        .playlistFormData {
            width: calc(100% - 48px);
            border: 1px solid #D3D3D3;
            border-radius: 8px;
            margin-bottom: 16px;
            padding: 16px;
            position: relative;

            .deleteButton {
                position: absolute;
                top: 20px;
                right: 20px;
                cursor: pointer;
                background-color: transparent;
                color: #2A61EB;
                border: none;
                outline: none;
            }

            label {
                span {
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22.4px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                }

                input {
                    margin-top: 10px;
                }
            }

            .genreDiv {
                margin-top: 10px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                column-gap: 16px;
                row-gap: 16px;

                .genre {
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16.8px;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    min-width: 109px;
                    padding: 8px 16px;
                    border: 1px solid #D1D1D1;
                    border-radius: 8px;
                    color: #545454;

                    @media screen and (max-width: 768px) {
                        // width: 33%;
                        min-width: auto;

                    }

                    &.selected {
                        background: linear-gradient(136.68deg, #2A61EB 4.55%, #7A3EEA 55.06%, #9035EA 110.89%);
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    .addMorePlaylsit {
        background-color: transparent;
        border: none;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;

        .plusSign {
            background: linear-gradient(136.68deg, #2A61EB 4.55%, #7A3EEA 55.06%, #9035EA 110.89%);
            width: 16px;
            height: 16px;
            aspect-ratio: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            margin-left: 10px;
        }
    }

    .buttonDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button.submitForm {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            border: 2px solid #2A61EB;

            padding: 12px 40px;
            color: #FFFFFF;
            background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
            border-radius: 8px;
            // border: none;
            margin-left: 16px;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                padding: 6px 0px;
                font-size: 12px;
                width: calc(50% - 10px);
            }
        }

        button.cancelForm {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            padding: 12px 40px;
            color: #000;
            background: white;
            border-radius: 8px;
            border: 2px solid #2A61EB;
            cursor: pointer;
            background: linear-gradient(90deg, #2A61EB 0%, #9035EA 100%);
            -webkit-background-clip: text;
            /* Clipping the background to the text */
            -webkit-text-fill-color: transparent;
            @media screen and (max-width: 768px) {
                padding: 6px 0px;
                font-size: 12px;
                width: calc(50% - 10px);
            }
        }
    }
}