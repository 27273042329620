.Campaign {
    border-left: 3px solid #9035EA;
    backdrop-filter: blur(3px);
    // margin-top: 23px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1098039216);
    margin-bottom: 13px;
    border-radius: 12px;

    .innerCampaign {
        padding: 21px 23px 18px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        .playlistDetail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

          

            .songDetail {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 50px;
                    border-radius: 7px;
                    margin-right: 9px;
                }

                .nameDate {
                    display: flex;
                    align-items: center;

                    @media screen and (max-width: 768px) {
                        flex-direction: column-reverse;
                        align-items: flex-start;
                    }

                    .dateStart {
                        margin-left: 20px;

                        @media screen and (max-width: 768px) {
                            margin: 0px;
                        }
                    }
                }

                .name {
                    h4 {
                        margin: 0px;
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 600;
                        letter-spacing: 0.02em;
                        text-align: left;
                        color: #242429;
                    }

                    p {
                        margin: 0px;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0.02em;
                        text-align: left;
                        color: #242429;

                    }
                }
            }

            .songTags {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-top: 15px;

                .tags {
                    background: linear-gradient(90deg, rgba(42, 97, 235, 0.3) 0%, rgba(144, 53, 234, 0.3) 100%);
                    padding: 1px 14px;
                    font-family: Poppins;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 19.5px;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #FFFFFF;
                    border-radius: 54px;
                    margin-right: 4px;
                }
            }
        }
        .status {
            @media screen and (max-width: 768px) {
                display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
            }
        }
        .budgetDiv {
            h5 {
                margin: 0px;
            }

            p {
                margin: 0px;
            }
        }

        .status {
            .statusText {
                font-family: Poppins;
                font-size: 14.53px;
                font-weight: 400;
                line-height: 27.26px;
                letter-spacing: 0.02em;
                text-align: right;
                color: #FD9C02;
                display: flex;
                align-items: center;
                margin: 0px;

                .statusDot {
                    background-color: #FD9C02;
                    width: 9px !important;
                    height: 9px !important;
                    position: block;
                    margin-left: 10px;
                    border-radius: 100px;
                }

                .budget {
                    font-family: Poppins;
                    font-size: 14.53px;
                    font-weight: 400;
                    line-height: 27.26px;
                    letter-spacing: 0.02em;
                    text-align: right;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #242429B2;
                    margin-left: 10px;
                }
            }

            .released {
                font-family: Poppins;
                font-size: 14.53px;
                font-weight: 400;
                line-height: 27.26px;
                letter-spacing: 0.02em;
                text-align: right;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #242429B2;
                margin: 0px;
                position: absolute;
                bottom: 18px;
                right: 23px;
            @media screen and (max-width: 768px) {
            position: static;
            }
            }
        }
    }
}