html {
  scroll-behavior: smooth;

}

.App {
  text-align: center;
}

* {
  font-family: Poppins;
}

button {
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

button {
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #242429;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.layout {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: #F5F5F5;

  @media screen and (max-width:1024px) {
    min-height: auto;
    max-height: unset;
  }

  .sideBar {
    width: 250px;
    min-height: 100vh;
    overflow: auto;
    // padding: 20px 0px;
    background: linear-gradient(180.01deg, #242429 0.01%, #242429 82.2%);

    @media screen and (max-width: 1024px) {
      display: none;
    }


    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--backgroundColor);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fff;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }

  .content {
    width: calc(100% - 250px);
    min-height: 100vh;
    background-color: #f6f5fd;
    overflow-y: unset;
    overflow-x: auto;
    position: relative;
    // padding: 10px 40px;
    // background-image: url('./Assets/Images/blob1.png');
    background-color: #F5F5F5;
    background-size: contain;
    background-repeat: no-repeat;

    &.content-compaign {
      width: calc(100% - 448px);

      @media screen and (max-width: 768px) {
        width: calc(100% - 24px);
        padding: 12px;
      }
    }

    @media screen and (max-width: 604px) {
      padding: 0px;
    }

    .innerContent {
      padding: 0px 40px 44px;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      min-height: 100vh;
      max-height: unset;

      .innerContent {
        padding: 0px 20px 44px;
      }
    }

    @media screen and (max-width: 768px) {
      .innerContent {
        padding: 0px 0px 44px;
      }
    }
  }
}



.innerLayout {
  width: 100%;
  background: #F5F5F5;


  .layoutBody {

    display: flex;
    width: 100%;

    &.campmaignLayoutBody {
      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.loader {
  width: 28px;
  height: 28px;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.newStylePopup {
  border: none;
  border-radius: 10px;
  padding: 0px;
  overflow: hidden;
  // background-color: #242429;
  padding: 0px !important;
  color: #242429B2;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px #0000001C;
  // box-shadow: -4px 0px 0px 0px #1677FF;
  border-left: 3px solid #1677FF;
  backdrop-filter: blur(3px);
}

.newStylePopup .ant-popover-inner {
  box-shadow: none !important;
  padding: 0px 10px;
  color: #242429B2;
  background-color: #fff;
  // box-shadow: 0px 4px 4px 0px #0000001C;
  // // box-shadow: -4px 0px 0px 0px #1677FF;
  // border-left: 3px solid #1677FF;
  // backdrop-filter: blur(3px);
  // margin-bottom:16px;
}

.newStylePopup .ant-popover-arrow,
.ant-tooltip-arrow {
  display: none;
}

.newStylePopup .ant-popover-title {
  background: #242429 !important;
  padding-top: 8px;
  padding-bottom: 8px;
}

.newStylePopup .ant-popover-inner-content {
  background: transparent !important;
  // color: #242429;
}

.newStylePopup .ant-tooltip-inner {
  background-color: #242429;
  box-shadow: none !important;
}

.hideMob {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.hidePc {
  @media screen and (min-width: 768px) {
    display: none !important;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.bgGrad {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}