.Budget {
    width: 100%;
    // background-color: white;
    // box-shadow: 8px 8px 29px 0px #50587114;
    // border-radius: 24px;
    // padding: 58px 24px 34px;

    .innerBudget {
        .amountRage {
            width: 100%;

            .amount {
                display: flex;
                align-items: center;
                flex-direction: column;
                align-items: center;

                h3 {
                    font-family: Poppins;
                    font-size: 64px;
                    font-weight: 600;
                    line-height: 64px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    background: linear-gradient(79.05deg, #9035EA 12.43%, #2B61EB 80.15%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin: 0px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    input {
                        font-family: Poppins;
                        font-size: 64px;
                        font-weight: 600;
                        line-height: 64px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        background: linear-gradient(79.05deg, #9035EA 12.43%, #2B61EB 80.15%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        margin: 0px;
                        height: 100%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        // width: 270px;
                        border: none;
                        outline: none;
                        background: transparent;

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;

                        }
                    }

                    .editButon {
                        background: transparent;
                        border: none;
                    }

                    sup {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        position: absolute;
                        top: 0px;
                        right: -40px;
                    }
                }

                p {
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 25.2px;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #4F4F4F;
                    margin: 0px;
                    margin-top: 14px;

                    span {
                        color: #2A61EB;
                    }
                }
            }

            .rangeSelector {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 48px;
                position: relative;
                margin-bottom: 63px;

                @media screen and (max-width:768px) {
                    margin-top: 24px;
                    margin-bottom: 24px;
                }

                span {
                    font-family: Poppins;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 32px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;

                    color: #000;
                    margin: 0px;

                    @media screen and (max-width:768px) {
                        font-size: 16px;
                    }
                }

                .rageSelectStepDiv {
                    max-width: 735px;
                    width: 100%;
                    margin: 0px 18px;
                    // position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @media screen and (max-width:768px) {
                        max-width: calc(100% - 122px);
                    }

                    .rageSelectStep {
                        width: 10px;
                        height: 10px;
                        background: #007AFF4D;
                        border-radius: 100px;
                        z-index: 1;
                        cursor: pointer;
                    }

                    .progressDiv {
                        max-width: 735px;
                        position: absolute;
                        width: 100%;
                        background: #D9D9D9;
                        height: 6px;
                        display: flex;
                        align-items: center;

                        @media screen and (max-width:768px) {
                            max-width: calc(100% - 122px);
                        }

                        .Progress {
                            background: linear-gradient(79.05deg, #9035EA 12.43%, #2B61EB 80.15%);

                            height: 10px;
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;
                        }
                    }

                    input {
                        max-width: 478.4px;
                        position: absolute;
                        width: 100%;
                    }

                    input[type="range"] {
                        -webkit-appearance: none;
                        /* Remove default styling */
                        width: 100%;
                        max-width: 735px;
                        height: 8px;
                        border-radius: 5px;
                        background: transparent;
                        outline: none;
                        transition: background 0.3s;

                        margin: 0px;

                        @media screen and (max-width:768px) {
                            max-width: calc(100% - 122px);
                        }
                    }

                    input[type="range"]::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        width: 20px;
                        height: 20px;
                        background: #007AFF;
                        border: 2px solid #007AFF;
                        border-radius: 50%;
                        cursor: pointer;
                        position: relative;
                        z-index: 2;
                    }

                    input[type="range"]::-moz-range-thumb {
                        width: 20px;
                        height: 20px;
                        background: #007AFF;
                        border: 2px solid #007AFF;
                        border-radius: 50%;
                        cursor: pointer;
                    }

                    input[type="range"]::-ms-thumb {
                        width: 20px;
                        height: 20px;
                        background: #007AFF;
                        border: 2px solid #007AFF;
                        border-radius: 50%;
                        cursor: pointer;
                    }

                }
            }
        }

        .tierDiv {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            column-gap: 13px;

            .currentTier {
                position: relative;
                width: 50%;
                background: #BCBCBC33;
                height: 156px;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .tierHover {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff00;
                    border-radius: 15px;
                    background: transparent;
                    border-radius: 15px;
                    backdrop-filter: blur(0px);
                    overflow: hidden;

                    .campaign {
                        // position: absolute;
                        // bottom: -100px;
                        cursor: pointer;
                        background: linear-gradient(79.05deg, #9035EA 12.43%, #2B61EB 80.15%);

                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 600;
                        // line-height: 31.43px;
                        text-align: center;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        padding: 12px 40px;
                        color: white;
                        border-radius: 8px;
                        border: none;
                        outline: none;
                        opacity: 0;
                        transition: 0.5s;

                    }
                }

                h3 {
                    font-family: Poppins;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 60px;
                    letter-spacing: -0.03em;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #868689;
                }

                p.estimateStreamTier {
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 800;
                    line-height: 27.26px;
                    letter-spacing: 0.02em;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #242429;
                    margin: 0px;
                }
            }

            .nextTier {
                position: relative;
                width: 50%;
                height: 156px;
                background: transparent;
                background-image: url('./../../Assets/Images/nextTierDIv.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .tierHover {
                    position: absolute;
                    // bottom: -100px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff00;
                    border-radius: 15px;
                    border-radius: 15px;
                    backdrop-filter: blur(0px);
                    overflow: hidden;

                    .campaign {
                        cursor: pointer;
                        background: linear-gradient(79.05deg, #9035EA 12.43%, #2B61EB 80.15%);

                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 27px;
                        text-align: center;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        width: 240px;
                        height: 51px;
                        color: white;
                        // padding: 12px 40px;
                        border: none;
                        border-radius: 8px;
                    }
                }

                h3 {
                    font-family: Poppins;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 60px;
                    letter-spacing: -0.03em;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #007AFF;
                }

                p.estimateStreamTier {
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 800;
                    line-height: 27.26px;
                    letter-spacing: 0.02em;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #242429;
                    margin: 0px;
                }

                .increaseText {
                    font-family: Poppins;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 27.26px;
                    letter-spacing: 0.02em;
                    text-align: center;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    margin: 0px;
                }
            }

            .nextTier:hover .tierHover,
            .currentTier:hover .tierHover {
                background-color: rgba(255, 255, 255, 0.301);
                backdrop-filter: blur(3px);

                .campaign {

                    opacity: 1;
                }


            }
        }

        .packages {
            margin-bottom: 16px;

            .innerPackages {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px 0px;

                @media screen and (max-width:768px) {
                    // padding: 0px 24px;
                }

                .packageDiv {
                    width: calc(25% - 25px);
                    background-color: #FFFFFF;
                    border-radius: 25px;
                    // border: 2px solid #0000004D;
                    // aspect-ratio: 1.038;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // box-shadow: 0px 5px 20.3px 0px #0000001A;
                    transition: 0.2s ease-in-out;
                    height: 300px;

                    // height: 356px;
                    @media screen and (max-width:768px) {
                        display: none;
                        max-width: 336px;
                        width: 100%;
                    }

                    .packageDetail {
                        height: 100%;
                        cursor: pointer;

                        .innerPackageDetail {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }


                    .packageImage {
                        width: 80px;
                        height: 80px;
                        border-radius: 100%;
                        background-color: #F6F6F6;
                        // box-shadow: 0px 3px 11.8px 0px #0000001A;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0px 0px 13px -6px black;

                        // left: 50%;
                        // transform: translate(-50%,-25px);
                        img {
                            width: 40px;
                            object-position: center;
                            object-fit: contain;
                        }
                    }

                    &.Platinum {
                        .packageImage {
                            background: linear-gradient(180deg, #007AFF 0%, #9035EA 100%);
                            background-color: unset;

                            img {
                                width: 90%;
                                height: 90%;
                            }
                        }
                    }

                    .packageName {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        h4 {
                            //styleName: Hairline 2;
                            font-family: Poppins;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 12px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;

                            color: #4F4F4F;
                            padding: 8px 12px;
                            background: #F6F6F6;
                            border-radius: 8px;
                            margin-top: 24px;
                            margin-bottom: 16px;

                        }

                        p {
                            font-family: Poppins;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 12px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            margin: 0px;
                            padding: 12px;
                            background: #F6F6F6;
                            color: #777E90;
                            margin-top: 16px;
                            margin-bottom: 16px;
                            border-radius: 10px;

                        }
                    }

                    .packageFeatures {
                        text-align: left;
                        width: 100%;
                        margin-bottom: 25px;

                        p {
                            font-family: Poppins;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 16px;
                            text-align: center;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;


                            color: #4F4F4F;
                            color: #777E90;
                            margin: 0px;

                            &.discountAmount {
                                margin-bottom: 12px;
                                color: #2C2C2C;
                            }

                            span {
                                font-family: Poppins;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 19.2px;
                                text-align: center;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;

                                color: #2B61EB;
                            }

                            &.discountText {
                                background: linear-gradient(90deg, #007AFF 0%, #9035EA 100%);
                                -webkit-background-clip: text;
                                /* Clipping the background to the text */
                                -webkit-text-fill-color: transparent;
                                font-weight: bold;

                                b {
                                    font-weight: bolder;
                                    font-family: Poppins;
                                    font-size: 18px;
                                    font-weight: 800;
                                    line-height: 43.2px;
                                    text-align: left;
                                    text-underline-position: from-font;
                                    text-decoration-skip-ink: none;

                                }
                            }
                        }
                    }

                    // background-size: cover;
                    &.active {
                        background: linear-gradient(133.43deg, #2A61EB -0.99%, #7A3EEA 46.95%, #9035EA 99.94%);
                        scale: 1;
                        z-index: 1;
                        box-shadow: 0px 8px 70.3px 10px rgba(0, 0, 0, 0.1019607843);
                        display: block;

                        @media screen and (max-width:768px) {
                            display: block;
                        }

                        .packageName {
                            h4 {
                                color: white;
                                background-color: #ffffff50;
                            }

                            p {
                                color: white;
                                background-color: #ffffff50;
                            }
                        }

                        .packageFeatures {
                            p {
                                color: white;

                                span {
                                    color: white;
                                }

                                svg {
                                    path {
                                        fill: white !important;
                                    }
                                }

                                &.discountText {
                                    -webkit-background-clip: unset !important;
                                    /* Clipping the background to the text */
                                    -webkit-text-fill-color: unset !important;
                                    background: transparent !important;
                                    color: white !important;

                                }
                            }
                        }

                        &.Platinum {
                            .packageImage {
                                background: #F6F6F6;
                            }
                        }
                    }

                    .tierHover {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #ffffff00;
                        background: transparent;
                        border-radius: 25px;
                        backdrop-filter: blur(0px);
                        overflow: hidden;
                        top: 0px;
                        left: 0px;

                        @media screen and (max-width:768px) {
                            display: none;
                        }

                        .campaign {
                            // position: absolute;
                            // bottom: -100px;
                            cursor: pointer;
                            background: linear-gradient(79.05deg, #9035EA 12.43%, #2B61EB 80.15%);

                            font-family: Poppins;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 27px;
                            text-align: center;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            width: 240px;
                            height: 51px;
                            color: white;
                            // padding: 12px 40px;
                            border: none;
                            border-radius: 8px;
                            opacity: 0;

                        }
                    }

                    &:hover .tierHover,
                    &:hover .tierHover {
                        background-color: rgba(255, 255, 255, 0.301);
                        backdrop-filter: blur(3px);

                        .campaign {

                            opacity: 1;
                        }


                    }
                }

            }

            .startCampaign {

                display: none;

                @media screen and (max-width:768px) {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 40px 0px 0px;

                    button {
                        // position: absolute;
                        // bottom: -100px;
                        cursor: pointer;
                        background: linear-gradient(79.05deg, #9035EA 12.43%, #2B61EB 80.15%);

                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 27px;
                        text-align: center;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        width: 240px;
                        height: 51px;
                        color: white;
                        // padding: 12px 40px;
                        border: none;
                        border-radius: 8px;
                        width: 100%;

                    }
                }
            }
        }
    }
}